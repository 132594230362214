import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import GlobalStyle from './styles/GlobalStyle'
import { mediaMaxWidth } from './styles/media'

// import Header from './Header'
import Header from './NewHeader'
// import Footer from './Footer'
import Footer from './NewFooter'
import SEO from './SEO/SEO'

const Container = styled.div`
  margin: 0 auto 0 auto;
  max-width: 1120px;
  /* padding: 0.25em 0; */
  padding-top: 1px;
  ${mediaMaxWidth('tablet')`
    margin: 0 auto 0 auto;
  `}
`

const Layout = ({ location, children, className, customSEO }) => {
  let header

  let rootPath = `/`
  if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
    rootPath = __PATH_PREFIX__ + `/`
  }

  return (
    <>
      {!customSEO && <SEO />}
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <GlobalStyle />
      <Container className={className}>
        <Header />
        {children}
        <Footer gridArea="footer" />
      </Container>
    </>
  )
}

export default Layout
