import Fraction from 'fraction.js'

const ingredientQuantityParser = (ingredient, enUS) => {
  let {
    ordinalQuantity,
    metricQuantity,
    metricUnit,
    standardUnit,
    standardQuantity,
  } = ingredient

  if (enUS) {
    ordinalQuantity = ordinalQuantity ? ordinalQuantity['en_US'] : null
    metricQuantity = metricQuantity ? metricQuantity['en_US'] : null
    metricUnit = metricUnit ? metricUnit['en_US'] : null
    standardUnit = standardUnit ? standardUnit['en_US'] : null
    standardQuantity = standardQuantity ? standardQuantity['en_US'] : null
  }

  // if ordinalQuantity is "AS_NEEDED"
  if (ordinalQuantity === 'AS_NEEDED' || standardUnit === 'AS_NEEDED')
    return 'as needed'

  // if other ordinal quantity
  if (ordinalQuantity) return parseQty(ordinalQuantity)

  if (metricUnit === 'ALL' || standardUnit === 'ALL') return 'all'

  // if not ordinal quantity, but there is no standard or metric qty
  if (!standardQuantity || !metricQuantity) return null

  // detect if unit name will need to be plural
  let pluralStd = standardQuantity > 1
  let pluralMtc = metricQuantity > 1

  const standard = `${parseQty(standardQuantity)} ${parseUnits(
    standardUnit,
    pluralStd
  )}`
  const metric = `${parseQty(metricQuantity)} ${parseUnits(
    metricUnit,
    pluralMtc
  )}`

  return { standard, metric }
}
export default ingredientQuantityParser

export const parseUnits = (units, isPlural = true) => {
  switch (units) {
    case 'ALL':
      return 'all'
    case 'AS_NEEDED':
      return 'as needed'
    case 'BUNCH':
      return !isPlural ? 'bunch' : 'bunches'
    case 'CLOVE':
      return !isPlural ? 'clove' : 'cloves'
    case 'CUP':
      return !isPlural ? 'cup' : 'cups'
    case 'GALLON':
      return !isPlural ? 'gallon' : 'gallons'
    case 'GRAM':
      return !isPlural ? 'gram' : 'grams'
    case 'HEAD':
      return !isPlural ? 'head' : 'heads'
    case 'LB':
      return !isPlural ? 'lb.' : 'lbs.'
    case 'LITER':
      return !isPlural ? 'liter' : 'liters'
    case 'ML':
      return 'ml'
    case 'OZ':
      return 'oz'
    case 'PIECE':
      return !isPlural ? 'piece' : 'pieces'
    case 'PINCH':
      return !isPlural ? 'pinch' : 'pinches'
    case 'PINT':
      return !isPlural ? 'pint' : 'pints'
    case 'QUART':
      return !isPlural ? 'quart' : 'quarts'
    case 'SPRIG':
      return !isPlural ? 'sprig' : 'sprigs'
    case 'SLICE':
      return !isPlural ? 'slice' : 'slices'
    case 'STICK':
      return !isPlural ? 'stick' : 'sticks'
    case 'TBSP':
      return !isPlural ? 'Tbsp.' : 'Tbsps.'
    case 'TSP':
      return !isPlural ? 'tsp.' : 'tsps.'
    case 'TURN':
      return !isPlural ? 'turn' : 'turns'
    default:
      break
  }
}

export const parseQty = qty => {
  let output = qty
  // if it has a decimal, convert to fraction string and replace with available symbols
  if (!Number.isInteger(qty)) {
    output = new Fraction(qty).simplify(0.1).toFraction(true)
    output = makeFractionUnicode(output)
  }
  return output
}

export const makeFraction = qty => {
  let output = qty
  // if it has a decimal, convert to fraction string
  if (!Number.isInteger(qty)) {
    output = new Fraction(qty).simplify(0.1).toFraction(true)
  }
  return output
}

export const makeFractionHtml = fractionStr =>
  fractionStr.replace(/ ?(\d+)\/(\d+)/g, ' <sup>$1</sup>&frasl;<sub>$2</sub>')

function makeFractionUnicode(input) {
  // find the fraction with a regex
  let fract = input.match(/(\d+)\/(\d+)/g)[0]
  // find the corresponding unicode vulgar fraction
  switch (fract) {
    case '1/2':
      fract = '\u00bd'
      break
    case '1/3':
      fract = '\u2153'
      break
    case '2/3':
      fract = '\u2154'
      break
    case '1/4':
      fract = '\u00bc'
      break
    case '3/4':
      fract = '\u00be'
      break
    case '1/5':
      fract = '\u2155'
      break
    case '2/5':
      fract = '\u2156'
      break
    case '3/5':
      fract = '\u2157'
      break
    case '4/5':
      fract = '\u2158'
      break
    case '1/6':
      fract = '\u2159'
      break
    case '5/6':
      fract = '\u215a'
      break
    case '1/8':
      fract = '\u215b'
      break
    case '3/8':
      fract = '\u215c'
      break
    case '5/8':
      fract = '\u215d'
      break
    case '7/8':
      fract = '\u215e'
      break
    case null:
      fract = ''
      break
    case undefined:
      fract = ''
      break
    default:
      fract = fract.replace(
        /(\d+)\/(\d+)/g,
        '<sup>$1</sup>&frasl;<sub>$2</sub>'
      )
  }
  // if it's an integer AND a fraction (e.g. "2 1/2"), replace the fraction portion ("2 ½")
  let [firstChar, secondChar] = input.split(' ')
  if (secondChar) return `${firstChar} ${fract}`
  return fract
}

export const schemaOrgIngredient = (ingredient, enUS = false) => {
  let {
    ordinalQuantity,
    metricQuantity,
    metricUnit,
    standardUnit,
    standardQuantity,
    name,
  } = ingredient

  if (enUS) {
    ordinalQuantity = ordinalQuantity ? ordinalQuantity['en_US'] : null
    metricQuantity = metricQuantity ? metricQuantity['en_US'] : null
    metricUnit = metricUnit ? metricUnit['en_US'] : null
    standardUnit = standardUnit ? standardUnit['en_US'] : null
    standardQuantity = standardQuantity ? standardQuantity['en_US'] : null
  }

  // if ordinalQuantity is "AS_NEEDED"
  if (ordinalQuantity === 'AS_NEEDED' || standardUnit === 'AS_NEEDED')
    return `${name} (as needed)`

  // if other ordinal quantity
  if (ordinalQuantity) return `${makeFraction(ordinalQuantity)} ${name}`

  // detect if unit name will need to be plural
  let pluralStd = standardQuantity > 1
  let pluralMtc = metricQuantity > 1

  const parsedStdUnits = parseUnits(standardUnit, pluralStd)
  const parsedMtrUnits = parseUnits(metricUnit, pluralMtc)
  const parsedStdQty = makeFraction(standardQuantity)
  const parsedMtrQty = makeFraction(metricQuantity)

  // if units are the same in standard and metric, "3 Tbsps. Oil"
  if (standardUnit === metricUnit)
    return `${parsedStdQty} ${parsedStdUnits} ${name}`

  // if units are different, "1/4 cup (60 grams) Flour"
  return `${parsedStdQty} ${parsedStdUnits} (${parsedMtrQty} ${parsedMtrUnits}) ${name}`
}
