import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, useStaticQuery, graphql } from 'gatsby'

import SchemaOrg from './SchemaOrg'

const SEO = ({ recipe, title, description, image, pathname, author }) => {
  const {
    site: {
      buildTime,
      siteMetadata: {
        defaultAuthor,
        defaultDescription,
        defaultImage,
        defaultTitle,
        facebookUsername,
        headline,
        instagramUsername,
        ogLanguage,
        siteLanguage,
        siteUrl,
        titleTemplate,
        twitterUsername,
      },
    },
  } = useStaticQuery(query)

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${image || defaultImage}`,
    url: `${siteUrl}${pathname || '/'}`,
    author: `${author || defaultAuthor}`
  }
  return (
    <>
      <Helmet defaultTitle={seo.title} titleTemplate={titleTemplate}>
        {/* General meta tags */}
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        {/* OpenGraph meta tags */}
        <meta property="og:site_name" content="Hestan Cue Recipes" />
        <meta property="og:url" content={seo.url} />
        {/* {isBlogPost && <meta property="og:type" content="article" />} */}
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={seo.image} />
        {/* TODO: Find source of fb:app_id. Copy/pasted from main site */}
        <meta property="fb:app_id" content="2059847880727869" />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={twitterUsername} />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />
      </Helmet>

      {/* Google Structured Data using Schema.org categories: https://schema.org/ */}
      <SchemaOrg
        title={seo.title}
        siteUrl={siteUrl}
        url={seo.url}
        recipeData={recipe}
        siteTitle={defaultTitle}
        headline={headline}
        siteLanguage={siteLanguage}
        description={seo.description}
        author={seo.author}
        buildTime={buildTime}
        image={seo.image}
      />
    </>
  )
}
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        defaultAuthor: author
        defaultDescription: description
        defaultImage: image
        defaultTitle: title
        facebookUsername
        headline
        instagramUsername
        ogLanguage
        siteLanguage
        siteUrl: url
        titleTemplate
        twitterUsername
      }
    }
  }
`
export default SEO
SEO.propTypes = {
  author: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  recipe: PropTypes.object,
  title: PropTypes.string,
}
SEO.defaultProps = {
  author: 'Hestan Smart Cooking',
  description: null,
  image: null,
  pathname: null,
  recipe: null,
  title: null,
}
