import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { mediaMaxWidth, breakpoints } from './styles/media'
import useWindowSize from './hooks/useWindowSize'
import './new-header.scss'

export default function NewHeader() {
  return (
    <div role="banner" className="navbar w-nav" id="awake-navbar">
      <div className="div-block-147">
        <input type="checkbox" id="nav-toggle" style={{ display: 'none' }} />
        <label
          htmlFor="nav-toggle"
          className="menu-button-2 unselectable w-nav-button"
          aria-label="menu"
          role="button"
          tabIndex="0"
        >
          <img
            src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f90c523c54bd2297a163449_hamburger_icon.svg"
            loading="lazy"
            alt=""
          />
        </label>
        <a
          href="https://www.hestancue.com/"
          className="brand w-nav-brand"
          aria-label="home"
        >
          <img
            src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f331d47afbc33c4ca4b17dc_Logo.svg"
            loading="lazy"
            alt=""
          />
        </a>
        <nav
          role="navigation"
          className="nav-menu-2 w-nav-menu desktop-nav-menu"
        >
          <div
            data-w-id="4f1bd105-b247-86dc-68fd-5a4ac09cf560"
            className="dropdown-wrapper-copy"
          >
            <a href="#" className="nav-link-2 dropdown-trigger nav-link-hover">
              HOW&nbsp;IT&nbsp;WORKS
            </a>
            <div className="w-layout-grid dropdown-content">
              <h2
                id="w-node-5a4ac09cf564-c09cf559"
                className="global-nav-heading"
              >
                Learn about the most advanced cooking system in the world.
              </h2>
              <a
                id="w-node-5a4ac09cf566-c09cf559"
                href="https://www.hestancue.com/cookware"
                className="global-nav-block w-inline-block"
              >
                <h3 className="global-nav-subheading">The Cookware</h3>
                <div className="global-nav-block-description">
                  Cookware that can feel.
                </div>
                <div className="global-nav-block-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459a8f2d29703a2cc8c7fb_Hestan%20Pans.svg"
                    alt=""
                    className="global-nav-block-image"
                  />
                </div>
              </a>
              <a
                id="w-node-5a4ac09cf56d-c09cf559"
                href="https://www.hestancue.com/cooktop"
                aria-current="page"
                className="global-nav-block w-inline-block"
              >
                <h3 className="global-nav-subheading">The Cooktop</h3>
                <div className="global-nav-block-description">
                  Precision induction heat.
                </div>
                <div className="global-nav-block-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459aa102f25f556b54c36c_Burner.svg"
                    alt=""
                    className="global-nav-block-image"
                  />
                </div>
              </a>
              <a
                id="w-node-5a4ac09cf574-c09cf559"
                href="https://www.hestancue.com/app"
                className="global-nav-block w-inline-block"
              >
                <h3 className="global-nav-subheading">The App</h3>
                <div className="global-nav-block-description">
                  Culinary intelligence,<span className="text-span-27"> </span>
                  guidance &amp; control.
                </div>
                <div className="global-nav-block-image-wrapper">
                  <img
                    src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459aaf03745d34a2060d48_Phone.svg"
                    alt=""
                    className="global-nav-block-image"
                  />
                </div>
              </a>
            </div>
          </div>
          <div className="nav-link-2 mobile-only nav-link-large">
            The Cue System
          </div>
          <a
            href="https://www.hestancue.com/cookware"
            className="nav-link-2 nav-link-sub mobile-only w-nav-link"
          >
            The Cookware
          </a>
          <a
            href="https://www.hestancue.com/cooktop"
            aria-current="page"
            className="nav-link-2 nav-link-sub mobile-only w-nav-link"
          >
            The Cooktop
          </a>
          <a
            href="https://www.hestancue.com/app"
            className="nav-link-2 nav-link-sub mobile-only w-nav-link"
          >
            The App
          </a>
          <a
            href="https://shop.hestancue.com/pages/collections"
            className="nav-link-2 shop-all mobile-only nav-link-large w-nav-link"
          >
            Shop All
          </a>
          <a
            href="https://shop.hestancue.com/pages/smartcookware"
            className="nav-link-2 nav-link-large nav-link-hover w-nav-link"
          >
            Why Smart Cookware?
          </a>
          <a
            href="https://shop.hestancue.com/blogs/blog-recipes"
            className="nav-link-2 nav-link-large nav-link-hover w-nav-link"
          >
            Blog
          </a>
          <a
            href="https://recipes.hestancue.com"
            className="nav-link-2 nav-link-large nav-link-hover w-nav-link"
          >
            Recipes
          </a>
          <a
            href="https://shop.hestancue.com/pages/collections"
            className="nav-link-2-copy shop-header-button w-nav-link"
          >
            Shop
          </a>
          <a
            href="https://shop.hestancue.com/cart"
            className="link-block-2 w-inline-block"
          >
            <img
              src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f331d47afbc3354014b17de_BasketLogo.svg"
              loading="lazy"
              alt=""
            />
          </a>
        </nav>
        <a
          href="https://shop.hestancue.com/cart"
          className="link-block-2 mobile-only w-inline-block"
        >
          <img
            src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f331d47afbc3354014b17de_BasketLogo.svg"
            loading="lazy"
            alt=""
          />
        </a>
        <div className="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0">
          <nav
            role="navigation"
            className="nav-menu-2 w-nav-menu"
            data-nav-menu-open=""
          >
            <div
              data-w-id="4f1bd105-b247-86dc-68fd-5a4ac09cf560"
              className="dropdown-wrapper-copy"
            >
              <a
                href="#"
                className="nav-link-2 dropdown-trigger nav-link-hover"
              >
                HOW&nbsp;IT&nbsp;WORKS
              </a>
              <div className="w-layout-grid dropdown-content">
                <h2
                  id="w-node-5a4ac09cf564-c09cf559"
                  className="global-nav-heading"
                >
                  Learn about the most advanced cooking system in the world.
                </h2>
                <a
                  id="w-node-5a4ac09cf566-c09cf559"
                  href="https://www.hestancue.com/cookware"
                  className="global-nav-block w-inline-block"
                >
                  <h3 className="global-nav-subheading">The Cookware</h3>
                  <div className="global-nav-block-description">
                    Cookware that can feel.
                  </div>
                  <div className="global-nav-block-image-wrapper">
                    <img
                      src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459a8f2d29703a2cc8c7fb_Hestan%20Pans.svg"
                      alt=""
                      className="global-nav-block-image"
                    />
                  </div>
                </a>
                <a
                  id="w-node-5a4ac09cf56d-c09cf559"
                  href="https://www.hestancue.com/cooktop"
                  aria-current="page"
                  className="global-nav-block w-inline-block"
                >
                  <h3 className="global-nav-subheading">The Cooktop</h3>
                  <div className="global-nav-block-description">
                    Precision induction heat.
                  </div>
                  <div className="global-nav-block-image-wrapper">
                    <img
                      src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459aa102f25f556b54c36c_Burner.svg"
                      alt=""
                      className="global-nav-block-image"
                    />
                  </div>
                </a>
                <a
                  id="w-node-5a4ac09cf574-c09cf559"
                  href="https://www.hestancue.com/app"
                  className="global-nav-block w-inline-block"
                >
                  <h3 className="global-nav-subheading">The App</h3>
                  <div className="global-nav-block-description">
                    Culinary intelligence,
                    <span className="text-span-27"> </span>guidance &amp;
                    control.
                  </div>
                  <div className="global-nav-block-image-wrapper">
                    <img
                      src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f459aaf03745d34a2060d48_Phone.svg"
                      alt=""
                      className="global-nav-block-image"
                    />
                  </div>
                </a>
              </div>
            </div>
            <a
              className="nav-link-2 mobile-only nav-link-large"
              href="https://www.hestancue.com"
            >
              The Cue System
            </a>
            <a
              href="https://www.hestancue.com/cookware"
              className="nav-link-2 nav-link-sub mobile-only w-nav-link w--nav-link-open"
            >
              The Cookware
            </a>
            <a
              href="https://www.hestancue.com/cooktop"
              aria-current="page"
              className="nav-link-2 nav-link-sub mobile-only w-nav-link w--nav-link-open"
            >
              The Cooktop
            </a>
            <a
              href="https://www.hestancue.com/app"
              className="nav-link-2 nav-link-sub mobile-only w-nav-link w--nav-link-open"
            >
              The App
            </a>
            <a
              href="https://shop.hestancue.com/pages/collections"
              className="nav-link-2 shop-all mobile-only nav-link-large w-nav-link w--nav-link-open"
            >
              Shop All
            </a>
            <a
              href="https://shop.hestancue.com/pages/smartcookware"
              className="nav-link-2 nav-link-large nav-link-hover w-nav-link w--nav-link-open"
            >
              Why Smart Cookware?
            </a>
            <a
              href="https://shop.hestancue.com/blogs/blog-recipes"
              className="nav-link-2 nav-link-large nav-link-hover w-nav-link w--nav-link-open"
            >
              Blog
            </a>
            <a
              href="https://recipes.hestancue.com"
              className="nav-link-2 nav-link-large nav-link-hover w-nav-link w--nav-link-open"
            >
              Recipes
            </a>
            <a
              href="https://shop.hestancue.com/pages/collections"
              className="nav-link-2-copy shop-header-button w-nav-link w--nav-link-open"
            >
              Shop
            </a>
            <a
              href="https://shop.hestancue.com/cart"
              className="link-block-2 w-inline-block"
            >
              <img
                src="https://assets.website-files.com/5f331d47afbc3306984b17bf/5f331d47afbc3354014b17de_BasketLogo.svg"
                loading="lazy"
                alt=""
              />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
