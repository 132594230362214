import { createGlobalStyle, css } from 'styled-components'
import {
  mediaMinWidth,
  mediaMaxWidth,
  mediaBetweenWidths,
} from './media'

const GlobalStyle = createGlobalStyle`
/* 
	 Meyer's CSS Reset 
	 http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}



/* Hestan Cue global styles */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
	color: #404040;
	font-family: 'Montserrat', sans-serif;
	font-size: 18px;
	font-weight: 300;
	line-height: 28px;
	h1 {
		color: #ffffff;
		font-size: 28px;
		font-weight: 700;
		line-height: 34px;
		text-shadow: 0px 2px 4px rgba(0,0,0,0.2);
		${
      '' /* ${mediaMinWidth('tablet')`
			font-size: 36px;
			line-height: 44px;
		`}
		${mediaMinWidth('desktop')`
			font-size: 40px;
			line-height: 48px;
		`} */
    }
	}
	h2 {
		color: #1A1A1A;
		font-size: 36px;
		font-weight: 700;
		line-height: 44px;
		${mediaMaxWidth('tablet')`
			font-size: 28px;
			line-height: 34px;
		`}
		${mediaMaxWidth('mobile')`
			font-size: 24px;
			line-height: 28px;
		`}
	}
	h3 {
		color: #1A1A1A;
		font-size: 18px;
		font-weight: 700;
		line-height: 28px;
		${'' /* ${mediaMinWidth('tablet')`
			font-size: 18px;
			line-height: 24px;
		`} */}
	}
	p, p.p1 {
		color: #404040;
		font-size: 18px;
		font-weight: 300;
		line-height: 28px;
	}
	p.p2 {
		font-size: 16px;
		line-height: 22px;
		${'' /* ${mediaMinWidth('desktop')`
			font-size: 16px;
			line-height: 26px;
		`} */}
	}
	p.p3 {
		font-size: 14px;
		line-height: 24px;
	}
}
#disqus_thread {
	a {
		color: #f47521;
	}
}
`

export default GlobalStyle
