import React, { useState } from 'react'
import styled from 'styled-components'
import './footer-styles.css'
const StyledFooter = styled.footer``
// function serialize(form) {
//   var field,
//     s = []
//   if (typeof form == 'object' && form.nodeName == 'FORM') {
//     var len = form.elements.length
//     for (let i = 0; i < len; i++) {
//       field = form.elements[i]
//       if (
//         field.name &&
//         !field.disabled &&
//         field.type != 'file' &&
//         field.type != 'reset' &&
//         field.type != 'submit' &&
//         field.type != 'button'
//       ) {
//         if (field.type == 'select-multiple') {
//           for (j = form.elements[i].options.length - 1; j >= 0; j--) {
//             if (field.options[j].selected)
//               s[s.length] =
//                 encodeURIComponent(field.name) +
//                 '=' +
//                 encodeURIComponent(field.options[j].value)
//           }
//         } else if (
//           (field.type != 'checkbox' && field.type != 'radio') ||
//           field.checked
//         ) {
//           s[s.length] =
//             encodeURIComponent(field.name) +
//             '=' +
//             encodeURIComponent(field.value)
//         }
//       }
//     }
//   }
//   return s.join('&').replace(/%20/g, '+')
// }

const NewFooter = () => {
  const [email, setEmail] = useState('')
  const handleSubmit = e => {
    e.preventDefault()
    // console.log('Email: ', email)
    // console.log('before :', e.target)
    // console.log('serialized: ', serialize(e.target))
    // fetch("https://www.hestancue.com/contact#newsletter_form",
    // {
    //   method: 'post',

    // })
    window.location.replace(
      'https://www.hestancue.com/challenge#newsletter_form'
    )
  }

  return (
    <div
      id="shopify-section-footer-classic"
      className="shopify-section footer footer--classic"
    >
      <footer className="footer__container footer">
        <section className="footer__content section is-width-wide has-background has-padding-top has-padding-bottom">
          <div className="container has-limit is-justify-space-between">
            <div
              id="shopify-section-footer-classic-1"
              className="footer__block block__footer-classic-1 block__link-list one-fourth medium-down--one-whole column"
            >
              <div className="footer__menu is-justify-space-between ">
                <p className="footer__heading">Hestan Cue</p>
                <ul>
                  <li className="footer__menu-link">
                    <a
                      href="https://www.hestancue.com/pages/about"
                      className=""
                    >
                      About
                    </a>
                  </li>
                  <li className="footer__menu-link">
                    <a
                      href="https://www.hestancue.com/pages/partners"
                      className=""
                    >
                      Partners
                    </a>
                  </li>
                  <li className="footer__menu-link">
                    <a
                      href="https://www.hestancue.com/pages/financing"
                      className=""
                    >
                      Financing
                    </a>
                  </li>
                </ul>
              </div>
              <div className="has-padding-bottom has-padding-top">
                <ul className="social-icons is-flex is-flex-wrap ">
                  <li>
                    <a
                      href="https://twitter.com/hestancue"
                      title="Hestan Cue on Twitter"
                      rel="me"
                      target="_blank"
                      className="icon-twitter"
                    >
                      <span className="icon " data-icon="twitter">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 100 100"
                        >
                          <g id="twitter">
                            <path d="M95,22.1A37,37,0,0,1,84.4,25a18.51,18.51,0,0,0,8.11-10.2,37.17,37.17,0,0,1-11.73,4.48A18.47,18.47,0,0,0,48.84,31.9a18.8,18.8,0,0,0,.48,4.21,52.42,52.42,0,0,1-38-19.29A18.49,18.49,0,0,0,17,41.47a18.63,18.63,0,0,1-8.36-2.32v.23a18.48,18.48,0,0,0,14.81,18.1,18.58,18.58,0,0,1-4.87.65,18.07,18.07,0,0,1-3.47-.34A18.48,18.48,0,0,0,32.33,70.61,37,37,0,0,1,9.4,78.5,40,40,0,0,1,5,78.25a52.23,52.23,0,0,0,28.3,8.3c34,0,52.53-28.13,52.53-52.52l-.06-2.39A37,37,0,0,0,95,22.1Z"></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.facebook.com/hestancue/"
                      title="Hestan Cue on Facebook"
                      rel="me"
                      target="_blank"
                      className="icon-facebook"
                    >
                      <span className="icon " data-icon="facebook">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 100 100"
                        >
                          <g id="facebook">
                            <path d="M57,27.73V38H72.83l-2.09,16H57V95H40.45V53.94H26.62V38H40.45V26.15C40.45,12.46,48.83,5,61,5h0a115.36,115.36,0,0,1,12.34.63V19.94H64.92C58.26,19.94,57,23.1,57,27.73Z"></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.youtube.com/hestancue"
                      title="Hestan Cue on YouTube"
                      rel="me"
                      target="_blank"
                      className="icon-youtube"
                    >
                      <span className="icon " data-icon="youtube">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 100 100"
                        >
                          <g id="youtube">
                            <path d="M79.77,18.85H20.23A15.22,15.22,0,0,0,5,34.08V65.92A15.22,15.22,0,0,0,20.23,81.15H79.77A15.22,15.22,0,0,0,95,65.92V34.08A15.22,15.22,0,0,0,79.77,18.85Zm-26,38.09L42.36,62.81a1.41,1.41,0,0,1-2-1.26V38.45a1.41,1.41,0,0,1,2-1.26l11.45,5.87,11.06,5.69a1.4,1.4,0,0,1,0,2.5Z"></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/hestancue/"
                      title="Hestan Cue on Instagram"
                      rel="me"
                      target="_blank"
                      className="icon-instagram"
                    >
                      <span className="icon " data-icon="instagram">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 100 100"
                        >
                          <g id="instagram">
                            <path d="M73.75,5H26.25A21.27,21.27,0,0,0,5,26.25v47.5A21.27,21.27,0,0,0,26.25,95h47.5A21.27,21.27,0,0,0,95,73.75V26.25A21.27,21.27,0,0,0,73.75,5Zm-8.6,60.13A21.43,21.43,0,1,1,71.44,50,21.4,21.4,0,0,1,65.15,65.13ZM81.9,29.34A7.62,7.62,0,1,1,84.14,24,7.59,7.59,0,0,1,81.9,29.34Z"></path>
                          </g>
                        </svg>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="shopify-section-1588901042268"
              className="footer__block block__1588901042268 block__link-list one-fourth medium-down--one-whole column"
            >
              <div className="footer__menu is-justify-space-between ">
                <p className="footer__heading">Customer Service</p>{' '}
                <ul>
                  {' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a
                      href="https://www.hestancue.com/pages/hestan-cue-guarantee-101-day-risk-free-trial"
                      className=""
                    >
                      Return Policy
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a href="https://support.hestancue.com/" className="">
                      Support
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a
                      href="https://support.hestancue.com/support/solutions"
                      className=""
                    >
                      FAQ
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a
                      href="https://www.hestancue.com/pages/use-care"
                      className=""
                    >
                      Guides
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a
                      href="https://www.hestancue.com/pages/accessibility-statement"
                      className=""
                    >
                      Accessibility Statement
                    </a>
                  </li>
                </ul>
              </div>
            </div>{' '}
            <div
              id="shopify-section-1588901088496"
              className="footer__block block__1588901088496 block__link-list one-fourth medium-down--one-whole column"
            >
              <div className="footer__menu is-justify-space-between ">
                {' '}
                <p className="footer__heading">Hestan Brands</p>{' '}
                <ul>
                  {' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a href="https://www.hestanvineyards.com/" className="">
                      Vineyards
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a href="https://commercial.hestan.com/" className="">
                      Commercial
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a
                      href="https://outdoor.hestan.com/tag/hestan-indoor/"
                      className=""
                    >
                      Indoor
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a href="https://home.hestan.com/" className="">
                      Outdoor
                    </a>
                  </li>{' '}
                  <li className="footer__menu-link">
                    {' '}
                    <a href="https://nanobond.hestan.com/" className="">
                      Cookware
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              id="shopify-section-1591839745306"
              className="footer__block block__1591839745306 block__newsletter one-fourth medium-down--one-whole column"
            >
              {' '}
              <p className="footer__heading">Join Us</p>{' '}
              <div className="content">
                {' '}
                <p>Sign up&nbsp;for recipes,&nbsp;events&nbsp;and more...</p>
              </div>
              {/* </div> */}
              <style data-shopify="">
                #shopify-section- .newsletter-form__wrapper {}
              </style>
              <div className="newsletter-form__wrapper ">
                {' '}
                <div className="form__success-message text-align-"></div>
                <form
                  // method="post"
                  // action="/contact#newsletter_form"
                  onSubmit={handleSubmit}
                  id="newsletter_form"
                  acceptCharset="UTF-8"
                  className="contact-form newsletter-form--newsletter-section"
                >
                  {/* Hidden fields */}
                  <input
                    type="hidden"
                    name="contact[tags]"
                    value="prospect,newsletter"
                  />
                  <input type="hidden" name="challenge" value="false" />
                  <div className="newsletter-form form is-responsive">
                    {' '}
                    <div className="field is-stretched-width">
                      {' '}
                      <label className="label newsletter__text ">
                        Email<span className="required">*</span>
                      </label>{' '}
                      <div className="control ">
                        {' '}
                        <input
                          className="input is-primary-btn-style is-regular"
                          type="email"
                          name="contact[email]"
                          placeholder=""
                          required=""
                          // value={email}
                          onChange={e => setEmail(e.target.value)}
                        />
                      </div>
                    </div>{' '}
                    <div className="field is-default-width is-align-self-end">
                      {' '}
                      <div className="control">
                        {' '}
                        <button
                          type="submit"
                          className="button button--primary is-within-form"
                        >
                          Sign Up
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

        <section className="footer__extra-content section">
          <div className="container has-padding-top has-padding-bottom">
            {/* <div className="one-whole column">
              <div className="footer-menu__disclosure footer-classic__disclosure">
                <form
                  method="post"
                  action="/localization"
                  id="footer__selector-form"
                  acceptCharset="UTF-8"
                  className="selectors-form"
                  encType="multipart/form-data"
                >
                  <input type="hidden" name="form_type" value="localization" />
                  <input type="hidden" name="utf8" value="✓" />
                  <input type="hidden" name="_method" value="put" />
                  <input type="hidden" name="return_to" value="/" />
                  <div className="localization footer-menu__disclosure">
                    <div className="selectors-form__wrap"></div>
                  </div>
                </form>
              </div>
            </div> */}
            <div className="footer__credits  one-whole  column small-down--one-whole">
              <p>
                © 2020{' '}
                <a href="https://www.hestancue.com/" title="">
                  Hestan Cue
                </a>
                .
              </p>
              <p>
                All rights reserved. |{' '}
                <a href="https://www.hestancue.com/pages/privacy-policy">
                  Privacy Policy
                </a>{' '}
                |{' '}
                <a href="https://www.hestancue.com/pages/terms-of-use">
                  Terms of Use
                </a>{' '}
                |{' '}
                <a href="https://www.hestancue.com/pages/terms-of-sales">
                  Terms of Sale
                </a>
              </p>
            </div>
          </div>
        </section>
      </footer>
    </div>
  )
}
export default NewFooter
