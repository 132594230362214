import { css } from 'styled-components'

export const breakpointsPx = {
  desktop: '1120px',
  tablet: '1024px',
  mobile: '768px',
  mobileSmall: '350px',
}
export const breakpoints = {
  desktop: 1120,
  tablet: 1024,
  mobile: 768,
  mobileSmall: 350,
}

export const mediaMinWidth = breakpoint => (...args) => css`
  @media (min-width: ${breakpointsPx[breakpoint]}) {
    ${css(...args)}
  }
`

export const mediaMaxWidth = breakpoint => (...args) => css`
  @media (max-width: ${breakpointsPx[breakpoint]}) {
    ${css(...args)}
  }
`

export const mediaBetweenWidths = (min, max) => (...args) => css`
  @media (min-width: ${breakpointsPx[min]}) and (max-width: ${breakpointsPx[
      max
    ]}) {
    ${css(...args)}
  }
`
