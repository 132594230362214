import React from 'react'
import Helmet from 'react-helmet'

import { schemaOrgIngredient } from '../../lib/ingredientQuantityParser'

// schema.org in JSONLD format
// https://developers.google.com/search/docs/guides/intro-structured-data
const SchemaOrg = ({
  title,
  siteUrl,
  url,
  siteTitle,
  headline,
  siteLanguage,
  description,
  author,
  buildTime,
  image,
  recipeData,
}) => {
  const orgSchema = {
    '@context': 'http://schema.org',
    '@type': 'Organization',
    name: 'Hestan Smart Cooking',
    url: 'https://www.hestancue.com',
    logo: {
      '@type': 'ImageObject',
      url: '/images/logo.png',
    },
    sameAs: [
      'https://www.facebook.com/hestancue',
      'https://twitter.com/hestancue',
      'https://www.pinterest.com/hestancue/',
      'https://www.instagram.com/hestancue/',
      'https://www.youtube.com/channel/UClaE7gWG5KhdJKbO9A9m4iA',
    ],
  }

  const websiteSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    url: siteUrl,
    name: siteTitle,
    alternateName: siteTitle,
    headline,
  }

  let webpageSchema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: url,
    headline,
    inLanguage: siteLanguage,
    mainEntityOfPage: siteUrl,
    // description: description,
    name: title,
    author: {
      '@type': 'Organization',
      name: author,
    },
    copyrightHolder: {
      '@type': 'Organization',
      name: author,
    },
    copyrightYear: '2019',
    creator: {
      '@type': 'Organization',
      name: author,
    },
    publisher: {
      '@type': 'Organization',
      name: author,
    },
    datePublished: '2019-11-30T10:30:00+01:00',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: `${siteUrl}${image}`,
    },
  }

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': siteUrl,
        name: 'Recipes',
      },
      position: 1,
    },
  ]

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  }

  let recipeSchema = null
  if (recipeData) {
    webpageSchema = {
      ...webpageSchema,
      headline: recipeData.synopsis.synopsis,
      description: recipeData.synopsis.synopsis,
      title: recipeData.title,
      image: {
        '@type': 'ImageObject',
        url: recipeData.image,
      },
    }
    itemListElement.push({
      '@type': 'ListItem',
      item: {
        '@id': url,
        name: recipeData.title,
      },
      position: 2,
    })

    // Parses out data from categories, defaults to empty array
    const {
      Cuisine: cuisines = [],
      'Dietary Restriction': dietaryRestrictions = [],
      'Getting Started': gettingStarted = [],
      'Main Ingredient': mainIngredients = [],
      'Meal / Course': mealsCourses = [],
      Mood: moods = [],
      Season: seasons = [],
      Vessel: vessels = [],
    } = recipeData.categories.reduce(
      (acc, { mainCategory, subCategory }, i) => {
        if (!acc[mainCategory]) {
          acc = { ...acc, [mainCategory]: [subCategory] }
        } else {
          acc = { ...acc, [mainCategory]: [...acc[mainCategory], subCategory] }
        }
        return acc
      },
      {}
    )

    // Formats ingredient quantity and units
    const recipeIngredients = recipeData.ingredients && recipeData.ingredients.map(ingredient => {
      return schemaOrgIngredient(ingredient)
    })

    // Formats eacb task sections into a "HowToSection" with a "HowToStep" for each task
    // https://developers.google.com/search/docs/data-types/recipe#guidance-recipe-instructions
    const tasksByStep = recipeData.tasks && recipeData.tasks.reduce((acc, task) => {
      if (!acc[task.taskStep]) {
        acc = { ...acc, [task.taskStep]: [task] }
      } else {
        acc = { ...acc, [task.taskStep]: [...acc[task.taskStep], task] }
      }
      return acc
    }, {})
    const recipeInstructions = tasksByStep && Object.keys(tasksByStep).map(
      (sectionName, i) => {
        const formattedSteps = tasksByStep[sectionName].map((task, j) => {
          // TODO: figure out how to add video and image structured data to recipe type
          // Video thumbnail images are required in order to include a video object
          // https://developers.google.com/search/docs/data-types/recipe
          const taskText = task.prompt
            ? `${task.title}; ${task.prompt.prompt}`
            : task.title
          return {
            '@type': 'HowToStep',
            position: j + 1,
            text: taskText,
          }
        })

        return {
          '@type': 'HowToSection',
          name: sectionName,
          position: i + 1,
          itemListElement: [...formattedSteps],
        }
      }
    )

    // https://schema.org/Recipe
    recipeSchema = {
      '@context': 'http://schema.org/',
      '@type': 'Recipe',
      name: recipeData.title,
      image: [recipeData.mediaUrl],
      author: {
        '@type': 'Organization',
        name: 'Hestan Smart Cooking',
      },
      datePublished: '2019-12-01',
      description: recipeData.synopsis.synopsis,
      prepTime: `PT${recipeData.prepTimeMinutes}M`, // format: 'PT20M'
      cookTime: `PT${recipeData.cookTimeMinutes}M`,
      totalTime: `PT${recipeData.prepTimeMinutes +
        recipeData.cookTimeMinutes}M`,
      recipeYield: `${recipeData.servings} servings`,
      recipeCategory: mealsCourses.length ? mealsCourses[0] : null,
      recipeCuisine: cuisines.length ? cuisines[0] : null,
      keywords: [
        ...dietaryRestrictions,
        ...mainIngredients,
        ...seasons,
        ...gettingStarted,
        ...vessels,
        ...moods,
      ].join(', '), // https://schema.org/keywords
      recipeIngredient: [...recipeIngredients],
      recipeInstructions: [...recipeInstructions],
    }
  }

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(orgSchema)}</script>
      <script type="application/ld+json">
        {JSON.stringify(websiteSchema)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(webpageSchema)}
      </script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      <script type="application/ld+json">{JSON.stringify(recipeSchema)}</script>
    </Helmet>
  )
}

export default SchemaOrg

// TODO: add rescources to README and delete from files
/* 
RESOURCES

Detailed article on building SEO into a GatsbyJS site: 
https://www.wesleylhandy.net/blog/seo-accessibility-first-gatsby.html

Jason Lengstorf's component to handle Google Structured Data using schema.org categories:
https://github.com/jlengstorf/gatsby-theme-jason-blog/blob/e6d25ca927afdc75c759e611d4ba6ba086452bb8/src/components/SEO/SchemaOrg.js
*/
